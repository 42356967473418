import { Routes, Route, Outlet } from 'react-router-dom'
import { Layout } from './Layout'
import { Products } from './products'
import { Purchases } from './purchases'
import { Review } from './review'
import i18n from 'i18next'
import translation from './translations/ar.json'
import { initReactI18next } from 'react-i18next'

i18n
	.use(initReactI18next)
	.init({
		resources: {
			ar: { translation }
		},
		lng: 'ar',
		fallbackLng: 'ar',
		interpolation: { escapeValue: false }
	})

function App() {
	return (
		<Routes>
			<Route path="/" element={<Outlet />}>
				<Route index element={<></>} />
				<Route path=":token" element={<Layout />}>
					<Route index element={<></>} />

					<Route path="prd" element={<Outlet />}>
						<Route index element={<></>} />
						<Route path=':id' element={<Products />} />
					</Route>

					<Route path="prs" element={<Outlet />}>
						<Route index element={<></>} />
						<Route path=':id' element={<Purchases />} />
					</Route>

					<Route path="rev" element={<Outlet />}>
						<Route index element={<></>} />
						<Route path=':id' element={<Review />} />
					</Route>

				</Route>
				<Route path="*" element={<>_</>} />
			</Route>
		</Routes>
	)
}

export default App
