import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Products.module.scss'

import { multiply, priceWithSign, fullDate, substract, precisionRound } from '../../utils'


interface ProductsProps {
  data?: Product[]
  setValue: (arg1: number, arg2: number) => void
}

export const Products: FC<ProductsProps> = ({ data, setValue }) => {
	const { t } = useTranslation()

	if (!data || data.length === 0) return <></>

	let paidCal = 0
	let priceCal = 0

	return (
		<div className="review-container">
			<h4 className="section">{t('client.products')}</h4>
			<div className="table-responsive">
				{data.map((product, id) => {
					const price = multiply(product.unitPrice, product.amount)

					paidCal += product.paid
					priceCal += price

					return (
						<table key={`products_${id}`} className={styles.productTable}>
							<tbody>
								<tr className={styles.productTableName}>
									<td className="product-name"> {product.name}</td>
									<td>({fullDate(product.createdAt)})</td>
								</tr>
								<tr>
									<table>
										<tbody>
											<tr>
												<td className="label">{t('product.amount')}</td>
												<td>
													{t('amountWithUnit', {
														amount: product.amount,
														unit: t(`unitsTranslation.${product.unit}`),
													})}
												</td>
											</tr>
											<tr>
												<td className="label">
													{t('purchase.unitPrice', {
														unit: t(`unitsTranslation.${product.unit}`),
													})}
												</td>
												<td>{product.unitPrice} $</td>
											</tr>
											<tr>
												<td className="label">{t('product.price')}:</td>
												<td>{price} $</td>
											</tr>
											<tr>
												<td className="label">{t('product.paid')}:</td>
												<td>{product.paid} $</td>
											</tr>
										</tbody>
									</table>
								</tr>
							</tbody>
						</table>
					)
				})}
			</div>
			<table className={styles.sum}>
				<tbody>
					<>
						<tr>
							<td colSpan={2}></td>
						</tr>
						<tr>
							<td>
								{t('product.priceCal')}: &nbsp;&nbsp;
								<span className={`value ${priceCal >= 0 ? 'positive' : 'negative'}`}>
									{priceCal} $
								</span>
							</td>
						</tr>
						<tr>
							<td>
								{t('product.paidCal')}: &nbsp;&nbsp;
								<span className={`value ${paidCal >= 0 ? 'positive' : 'negative'}`}>
									{paidCal} $
								</span>
							</td>
						</tr>
						<tr>
							<td>
					&nbsp;&nbsp;&nbsp;&nbsp;
								<span className={`value ${substract(paidCal, priceCal) >= 0 ? 'positive' : 'negative'}`}>
									{substract(paidCal, priceCal)} $
								</span>
							</td>
						</tr>
						{setValue(paidCal, priceCal)}
					</>
				</tbody>
			</table>
		</div>
	)
}
