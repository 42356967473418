import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { precisionRound } from '../utils/math'

export const Client:FC<{ title: string, finalSum: number }> = ({ title, finalSum }) => {
	const { t } = useTranslation()

	let translation = t('client.finalSum')
	if (finalSum > 0) translation = t('client.finalSumPlus')
	if (finalSum < 0) translation = t('client.finalSumMinus')

	return (
		<div className='container'>
			<div className='table-responsive'>
				<table>
					<tbody>
						<tr>
							<td colSpan={2} className='text-break-spaces'>
								<strong>{title}</strong>
							</td>
						</tr>
						<tr>
							<td>{translation}</td>
							<td
								className={`value ${finalSum >= 0 ? 'positive' : 'negative'}`}
								colSpan={3}
							>
								{Math.abs(precisionRound(finalSum, 5))} $
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}
