import { FC, ReactNode } from 'react'
import styles from './Invoice.module.css'
// import Image from 'next/image'
interface InvoiceProps {
	children: ReactNode
	title: string
}
export const Invoice: FC<InvoiceProps> = ({ children, title }) => (
	<>
		<main className={styles.main}>
			<div className={styles.header}>
				{/* <div className={styles.details}>
					<h4>{title}</h4>
				</div> */}
			</div>
			<div className={styles.content}>
				{children}
			</div>
		</main>
	</>
)
