import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

const GET_PRODUCT = gql`
query invoiceProduct($id: String!) {
  invoiceProduct(id: $id) {
    id
    name
    amount
    unit
    unitPrice
    paid
    notes
    createdAt
    client {
      id
      name
    }
    extraCosts {
      id
      paid
      order
      notes
    }
    available
    createdAt
    updatedAt
    purchaseItems {
      id
      price
      extraCosts
      purchase {
        id
        client {
          id
          name
        }
      }
      amount
      product {
        id
        unit
        name
        createdAt
      }
    }
  }
}
`

export const useProducts = () => {
	const { token, id } = useParams()

	const getProducts = () => useQuery(GET_PRODUCT, {
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'cache-first',
		variables: {
			id,
		},
		context: {
			headers: {
				authorization: `Bearer ${token}`,
			},
		},
	})


	return { getProducts }
}
