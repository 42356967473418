import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Invoice } from '../components'
import { usePurchases } from './usePurchases'
import { calculatePurchaseItemsValue, fullDate, substract, devide } from '../utils'

export function Purchases() {
	const { getPurchases } = usePurchases()
	const { data } = getPurchases()
	console.log(data)
	const { t } = useTranslation()

	if (!data?.invoicePurchase) return <></>

	const title = t('purchase.title', { name: data.invoicePurchase.client.name })

	return (
		<div>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<Invoice title={title}>
				<div className='table-container'>
					<table className="dre">
						<tbody>
							{data.invoicePurchase.purchaseItems.map((purchaseItem: any, index: any) => (
								<Fragment key={`products_list_${index}`}>
									<tr>
										<td><strong>{t('purchase.item')}</strong></td>
										<td><strong>{purchaseItem.product.name}</strong></td>
									</tr>
									<tr>
										<td>{t('purchase.amount')}</td>
										<td>
											{t('amountWithUnit', {
												amount: purchaseItem.amount,
												unit: t(`unitsTranslation.${purchaseItem.product.unit}`),
											})}
										</td>
									</tr>
									<tr>
										<td>
											{t('purchase.unitPrice', {
												unit: t(`unitsTranslation.${purchaseItem.product.unit}`),
											})}
										</td>
										<td>
											{t('purchase.unitPriceValue', {
												value: devide(purchaseItem.price, purchaseItem.amount)
											})}
										</td>
									</tr>
									<tr>
										<td>
											{t('purchase.priceTotal')}
										</td>
										<td>
											{t('priceWithSign', { price: purchaseItem.price })}
										</td>
									</tr>
								</Fragment>
							))}
							<tr>
								<td> {t('purchase.paid')}</td>
								<td>
									{t('priceWithSign', { price: data.invoicePurchase.paid })}
								</td>
							</tr>
							<tr>
								<td> {t('purchase.rest')}</td>
								<td>
									{t('priceWithSign', {
										price: substract(calculatePurchaseItemsValue( data.invoicePurchase.purchaseItems),  data.invoicePurchase.paid)
									})}
								</td>
							</tr>
							<tr>
								<td>
									{t('purchase.driverName')}
								</td>
								<td> {data.invoicePurchase.driverName || '-'}</td>
							</tr>
							<tr>
								<td>
									{t('purchase.driverPhone')}
								</td>
								<td> {data.invoicePurchase.driverPhone || '-'}</td>
							</tr>
							<tr>
								<td>
									{t('product.createdAt')}
								</td>
								<td> {fullDate(data.invoicePurchase.createdAt)}</td>
							</tr>
							<tr>
								<td>
									{t('product.updatedAt')}
								</td>
								<td> {fullDate(data.invoicePurchase.updatedAt)}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Invoice>
		</div>
	)
}
