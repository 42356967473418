import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { priceWithSign, fullDate } from '../utils'

interface BalancesProps {
	data?: Balance[]
	setValue: (arg: number) => void
}

export const Balances: FC<BalancesProps> = ({ data, setValue }) => {
	const { t } = useTranslation()
	let balance = 0

	if (!data) return null
	const positive: Balance[] = []
	let positiveSum = 0

	const negative: Balance[] = []
	let negativeSum = 0
	
	data.forEach(b => {
		balance += b.paid

		if (b.paid >= 0) {
			positive.push(b)
			positiveSum += b.paid
		} else {
			negative.push(b)
			negativeSum += b.paid
		}
	})
	return (
		<div className="review-container">
			<h4 className="section">{t('client.balances')}</h4>
			<table>
				<tbody>
					<>
						{positive.length !== 0 && (<tr>
							<td colSpan={2}></td>
							<td colSpan={3} className='value positive'>
								{t('balance.positive')} {priceWithSign(positiveSum)}
							</td>
							<td className='balance-note' colSpan={2}></td>
						</tr>)}
						{positive.map(b => {
							return (
								<tr key={b.id}>
									<td colSpan={2} className='value positive'>
										{priceWithSign(b.paid)}
									</td>
									<td colSpan={2}>{fullDate(b.createdAt)}</td>
									<td className='balance-note' colSpan={2}>{b.notes}</td>
								</tr>
							)
						})}
						{negative.length !== 0 && (
							<>
								<tr><td colSpan={5}></td></tr>
								<tr>
									<td colSpan={2}></td>
									<td colSpan={2} className='value negative'>
										{t('balance.negative')}  {priceWithSign(negativeSum)}
									</td>
									<td className='balance-note' colSpan={2}></td>
								</tr>
							</>
						)}
						{negative.map(b => {
							return (
								<tr key={b.id}>
									<td colSpan={2} className='value negative'>
										{priceWithSign(b.paid)}
									</td>
									<td colSpan={2}>{fullDate(b.createdAt)}</td>
									<td className='balance-note' colSpan={2}>{b.notes}</td>
								</tr>
							)
						})}
						{setValue(balance)}
					</>
				</tbody>
			</table>
		</div>
	)
}
