import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Invoice } from '../components'
import { useReviews } from './useReviews'
import { fullDate, precisionRound } from '../utils'
import { Client } from './Client'
import { Products } from './Products'
import { Balances } from './Balances'
import { Purchases } from './Purchases'
import { useSearchParams } from 'react-router-dom'

export function Review() {
	let finalSum = 0
	const [searchParams] = useSearchParams()
	const [productValue, setProductValue] = useState<number | undefined>()
	const [balanceValue, setBalanceValue] = useState<number | undefined>()
	const [purchaseValue, setPurchaseValue] = useState<number | undefined>()

	const { getReviews } = useReviews()

	const { data } = getReviews()
	const { t } = useTranslation()

	if (!data?.invoiceClient) return <></>


	const startDateParam = searchParams.get('startDate')
	const endDateParam = searchParams.get('endDate')

	const startDate = new Date(String(startDateParam))
	const endDate = new Date(String(endDateParam))

	const filterByDate = (items: any, dateField: any) => { // TODO: typing
		if (!startDateParam || !endDateParam) return items

		return items.filter((item: any) => {
			const date = new Date(item[dateField])
			return date >= startDate && date <= endDate
		})
	}

	let filteredProducts = []
	let filteredBalances = []
	let filteredPurchases = []

	if (searchParams.get('stocktaked') === 'true') {
		console.log('stocktaked')
		console.log(data.invoiceClient.productsStocktaked)
		console.log(data.invoiceClient.balancesStocktaked)
		console.log(data.invoiceClient.purchasesStocktaked)
		filteredProducts = filterByDate(data.invoiceClient.productsStocktaked, 'createdAt')
		filteredBalances = filterByDate(data.invoiceClient.balancesStocktaked, 'createdAt')
		filteredPurchases = filterByDate(data.invoiceClient.purchasesStocktaked, 'createdAt')
	} else {
		filteredProducts = filterByDate(data.invoiceClient.products, 'createdAt')
		filteredBalances = filterByDate(data.invoiceClient.balances, 'createdAt')
		filteredPurchases = filterByDate(data.invoiceClient.purchases, 'createdAt')
	}

	console.log({

		filteredProducts,
		filteredBalances,
		filteredPurchases,
	})
	const handleSetProductValue = (paid: number, price: number) => {
		if (!productValue) setProductValue(precisionRound(price - paid, 5))
	}

	const handleSetBalanceValue = (balance: number) => {
		if (!balanceValue) setBalanceValue(precisionRound(balance, 5))
	}

	const handleSetPurchaseValue = (total: number) => {
		if (!purchaseValue) setPurchaseValue(total)
	}

	finalSum = Number(productValue || 0) + Number(balanceValue || 0) + Number(purchaseValue || 0)

	const title = (!startDateParam || !endDateParam)
		? t('client.title', { name: data.invoiceClient.name })
		: t('client.titleWithDate', {
			name: data.invoiceClient.name,
			start: fullDate(startDate),
			end:  fullDate(endDate),
		})
	return (
		<div>
			<Helmet>
				<title>{title}</title>
			</Helmet>

			<Invoice title={title}>
				<Client title={title} finalSum={finalSum} />
				<Products
					data={filteredProducts}
					setValue={handleSetProductValue}
				/>
				<Balances
					data={filteredBalances}
					setValue={handleSetBalanceValue}
				/>
				<Purchases
					data={filteredPurchases}
					setValue={handleSetPurchaseValue}
				/>
			</Invoice>
		</div>
	)
}
