import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

interface ReviewsApi {
  invoiceClient: Client
}

const GET_CLIENT = gql`
  query($id: String!) {
    invoiceClient(id: $id) {
      id
      name
      products {
        id
        name
        amount
        unit
        unitPrice
        paid
        createdAt
        updatedAt
        extraCosts {
          id
        }
        purchaseItems {
          id
        }
      }
      productsStocktaked {
        id
        name
        amount
        unit
        unitPrice
        paid
        createdAt
        updatedAt
        extraCosts {
          id
        }
        purchaseItems {
          id
        }
      }
      balances {
        id
        notes
        paid
        createdAt
      }
      balancesStocktaked {
        id
        notes
        paid
        createdAt
      }
      purchases {
        id
        paid
        notes
        createdAt
        driverName
        driverPhone
        purchaseItems {
          id
          amount
          price
          extraCosts
          createdAt
          product {
            id
            name
            unit
          }
        }
      }
      purchasesStocktaked {
        id
        paid
        notes
        createdAt
        driverName
        driverPhone
        purchaseItems {
          id
          amount
          price
          extraCosts
          createdAt
          product {
            id
            name
            unit
          }
        }
      }
    }
  }
`

export const useReviews = () => {
	const { token, id } = useParams()

	const getReviews = () => useQuery<ReviewsApi>(GET_CLIENT, {
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'cache-first',
		variables: {
			id,
		},
		context: {
			headers: {
				authorization: `Bearer ${token}`,
			},
		},
	})


	return { getReviews }
}
