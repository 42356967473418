import { Fragment, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { fullDate, devide, substract, calculatePurchaseItemsValue, precisionRound } from '../utils'

interface PurchasesProps {
	data?: Purchase[]
	setValue: (arg: number) => void
}

export const Purchases: FC<PurchasesProps> = ({ data, setValue }) => {
	const { t } = useTranslation()

	let purchaseCalc = 0
	let paidCalc = 0

	if (!data) return null

	return (
		<div className="review-container">
			<>
				<h4 className="section">{t('client.purchases')}</h4>
				{data?.map((purchase) => {
					purchaseCalc = purchaseCalc + calculatePurchaseItemsValue(purchase.purchaseItems)
					paidCalc += purchase.paid

					return (
						<Fragment key={purchase.id}>
							<table className="dre">
								<tbody>
									{purchase.purchaseItems.map((purchaseItem: any, index: any) => (
										<Fragment key={`products_list_${index}`}>
											<tr>
												<td><strong>{t('purchase.item')}</strong></td>
												<td><strong>{purchaseItem.product.name}</strong></td>
											</tr>
											<tr>
												<td>{t('purchase.amount')}</td>
												<td>
													{t('amountWithUnit', {
														amount: purchaseItem.amount,
														unit: t(`unitsTranslation.${purchaseItem.product.unit}`),
													})}
												</td>
											</tr>
											<tr>
												<td>
													{t('purchase.unitPrice', {
														unit: t(`unitsTranslation.${purchaseItem.product.unit}`),
													})}
												</td>
												<td>
													{t('purchase.unitPriceValue', {
														value: devide(purchaseItem.price, purchaseItem.amount)
													})}
												</td>
											</tr>
											<tr>
												<td>
													{t('purchase.priceTotal')}
												</td>
												<td>
													{t('priceWithSign', { price: purchaseItem.price })}
												</td>
											</tr>
										</Fragment>
									))}
									<tr>
										<td> {t('purchase.paid')}</td>
										<td>
											{t('priceWithSign', { price: purchase.paid })}
										</td>
									</tr>
									<tr>
										<td> {t('purchase.rest')}</td>
										<td>
											{t('priceWithSign', {
												price: substract(calculatePurchaseItemsValue( purchase.purchaseItems),  purchase.paid)
											})}
										</td>
									</tr>
									<tr>
										<td>
											{t('purchase.driverName')}
										</td>
										<td> {purchase.driverName || '-'}</td>
									</tr>
									<tr>
										<td>
											{t('purchase.driverPhone')}
										</td>
										<td> {purchase.driverPhone || '-'}</td>
									</tr>
									<tr>
										<td>
											{t('product.createdAt')}
										</td>
										<td> {fullDate(purchase.createdAt)}</td>
									</tr>
									<tr>
										<td>
											{t('product.updatedAt')}
										</td>
										<td> {fullDate(purchase.updatedAt)}</td>
									</tr>
								</tbody>
							</table>
						</Fragment>
					)
				})}
				<tr>
					<td colSpan={2}></td>
				</tr>
				<tr>
					<td>{t('purchase.purchaseCalc')}</td>
					<td>{precisionRound(purchaseCalc, 5)} $</td>
				</tr>
				<tr>
					<td>{t('purchase.paidCalc')}</td>
					<td>{precisionRound(paidCalc, 5)} $</td>
				</tr>
				<tr>
					<td>{t('purchase.finalCalc')}</td>
					<td>
						<span className={`value ${substract(paidCalc, purchaseCalc) >= 0 ? 'positive' : 'negative'}`}>
							{substract(paidCalc, purchaseCalc)} $
						</span>
					</td>
				</tr>
				{setValue(substract(paidCalc, purchaseCalc))}
			</>
		</div>
	)
}
