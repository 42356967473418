import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import image from '../assets/images/kobo-logo.png'
import { fullDate } from '../utils/date'
import styles from './Layout.module.css'

export const Layout = () => {
	const { t } = useTranslation()
	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<div className={styles.header}>
					<div className={styles.logo}>
						<img src={image} alt='Kobo Trade' title='Kobo Trade' />
					</div>
					<div className={styles.description}>
						<h3 className="small">{t('layout.header.title')}</h3>
						<span className="small">({ fullDate(new Date()) })</span>
					</div>
				</div>
				<div className={styles.content}>
					<Outlet />
				</div>
				<div className={styles.footer}>
					<div className={styles.block}>
						<p>
							<strong>العنوان: </strong> <br />
              قيصرية التجار، القامشلي، سوريا
						</p>
					</div>
					<div className={styles.block}>
						<p dir="ltr">
							<a href="tel:+963938994626">+963 938 994626</a><br />
							<a href="mailto:contact@kobo-trade.com">contact@kobo-trade.com</a><br />
							<a href="https://kobo-trade.com" target="_blank" rel="noreferrer">kobo-trade.com</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
