// import React, { useEffect, useState } from 'react'
// import { useRouter } from "next/router"
// import Head from 'next/head'
import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
// import { client } from '../../../apollo-client'
// import { translations } from "../../../utils/translations";
// import { fullDate } from "../../../utils/date";
// import { multiply, substract } from "../../../utils/math";
// import { Invoice } from '../../../components'

const GET_PURCHASE = gql`
  query($id: String!) {
    invoicePurchase(id: $id) {
      id
      notes
      paid
      createdBy
      updatedBy
      driverName
      driverPhone
      purchaseItems {
        id
        amount
        price
        extraCosts
        product {
          id
          name
          unit
        }
      }
      client {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`

// const ProductInvoice = ({ data }) => {
//   const { query: { token, id } } = useRouter()
//   const [invoiceProduct, setInvoiceProduct] = useState()

//   const getData = async () => {
//     const { data: { invoiceProduct: invoiceProductData } } =  await client
//     .query({
//       query: GET_PRODUCT,
//       variables: { id },
//       context: { headers: { authorization: `Bearer ${token}` } },
//     })
//     .then(result => result).catch(() => ({}))
//     setInvoiceProduct(invoiceProductData)
//   }


//   useEffect(() => {
//     if (!invoiceProduct) getData()
//   }, [])

//   let invoice = invoiceProduct || data

//   const price = multiply(invoice.unitPrice, invoice.amount)

//   return (
//     <div className="page">
//       <Head>
//         <title>
//           {translations.product.title(invoice.client.name)}
//         </title>
//         <link rel="icon" href="/favicon.png" />
//       </Head>
//       <Invoice title={translations.product.title(invoice.client.name)}>
//         <table>
//           <tr>
//             <td> <strong>{translations.product.name}</strong></td>
//             <td> {invoice.name}</td>
//           </tr>
//           <tr>
//             <td> <strong>{translations.product.amount}</strong></td>
//             <td>
//               {translations.amountWithUnit(
//                 invoice.amount,
//                 translations.units(invoice.unit),
//               )}
//             </td>
//           </tr>
//           <tr>
//             <td> <strong>{translations.product.unitPrice}</strong></td>
//             <td>{translations.priceWithSign(invoice.unitPrice)}</td>
//           </tr>
//           <tr>
//             <td> <strong>{translations.product.totalPrice}</strong></td>
//             <td>{translations.priceWithSign(price)}</td>
//           </tr>
//           <tr>
//             <td> <strong>{translations.product.paid}</strong></td>
//             <td>{translations.priceWithSign(invoice.paid)}</td>
//           </tr>
//           <tr>
//             <td> <strong>{translations.product.rest}</strong></td>
//             <td>{translations.priceWithSign(
//               substract(price, invoice.paid)
//             )}</td>
//           </tr>
//           <tr>
//             <td> <strong>{translations.product.createdAt}</strong></td>
//             <td> {fullDate(invoice.createdAt)}</td>
//           </tr>
//           <tr>
//             <td> <strong>{translations.product.updatedAt}</strong></td>
//             <td> {fullDate(invoice.updatedAt)}</td>
//           </tr>
//         </table>

//       </Invoice>
//     </div>
//   )
// }

export const usePurchases = () => {
	const { token, id } = useParams()

	const getPurchases = () => useQuery(GET_PURCHASE, {
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'cache-first',
		variables: { id },
		context: {
			headers: {
				authorization: `Bearer ${token}`,
			},
		},
	})


	return { getPurchases }
}


// export const getServerSideProps = async ({ query }) => {
//   const { token, id } = query
//   const { data } = await client
//     .query({
//       query: GET_PRODUCT,
//       variables: {
//         id,
//       },
//       context: {
//         headers: {
//           authorization: `Bearer ${token}`,
//         },
//       },
//     })
//     .then(result => result).catch(() => ({}))

//   return { props: { data: data?.invoiceProduct } }
// }

// export default ProductInvoice
