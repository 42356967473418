import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Invoice } from '../components'
import { useProducts } from './useProducts'
import { multiply, priceWithSign, fullDate, substract } from '../utils'

export function Products() {
	const { getProducts } = useProducts()
	const { data } = getProducts()
	const { t } = useTranslation()
  
	if (!data?.invoiceProduct) return <></>
  
	const price = multiply(data.invoiceProduct.unitPrice, data.invoiceProduct.amount)
	const title = t('product.title', { name: data.invoiceProduct.client.name })
	return (
		<div>
			<Helmet>
				<title>{title}</title>
			</Helmet>
      
			<Invoice title={title}>
				<div className='table-container'>
					<table>
						<tbody>
							<tr>
								<td> <strong>{t('product.name')}</strong></td>
								<td> {data.invoiceProduct.name}</td>
							</tr>
							<tr>
								<td> <strong>{t('product.amount')}</strong></td>
								<td>
									{t('amountWithUnit', {
										amount: data.invoiceProduct.amount,
										unit: t(`unitsTranslation.${data.invoiceProduct.unit}`),
									})}
								</td>
							</tr>
							<tr>
								<td> <strong>{t('product.unitPrice')}</strong></td>
								<td>{priceWithSign(data.invoiceProduct.unitPrice)}</td>
							</tr>
        
							<tr>
								<td> <strong>{t('product.totalPrice')}</strong></td>
								<td>{priceWithSign(price)}</td>
							</tr>
							<tr>
								<td> <strong>{t('product.paid')}</strong></td>
								<td>{t('priceWithSign', { price: data.invoiceProduct.paid })}</td>
							</tr>
							<tr>
								<td> <strong>{t('product.rest')}</strong></td>
								<td>{priceWithSign(
									substract(price, data.invoiceProduct.paid)
								)}</td>
							</tr>
							<tr>
								<td> <strong>{t('product.createdAt')}</strong></td>
								<td> {fullDate(data.invoiceProduct.createdAt)}</td>
							</tr>
							<tr>
								<td> <strong>{t('product.updatedAt')}</strong></td>
								<td> {fullDate(data.invoiceProduct.updatedAt)}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Invoice>
		</div>
	)
}
